import { Calendar, ConfigProvider } from "antd"
import enEn from "antd/es/locale/en_US"
import esES from "antd/es/locale/es_ES"
import "dayjs/locale/es"
import moment from "moment"
import "moment/locale/es"
import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import MonthDropdown from "../../../components/MonthSelect"
import CompanyLayout from "../../../layout/CompanyLayout"
import { Images } from "../../../theme/Images"
import strings from "../../../translation"
import { getLanguage } from "../../../utils/functions"
import { getTrainingSchedule } from "../CompanyHome/redux/actions"
import PasswordModal from "./components/PasswordModal"
import ScheduleMeetingModal from "./components/ScheduleMeetingModal"
import { getCalendar } from "./redux/action"
import "./style.scss"

const CompanyCalender = () => {
  const dispatch = useDispatch()
  const calendarData = useSelector(state => state.calendar.calendar)
  const lang = useSelector(state => state.login.language)
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [selectedDate, setSelectedDate] = useState()
  const [dateId, setDateId] = useState(false)
  const [dates, setDates] = useState([])
  const [loading, setLoading] = useState(true)
  const onPanelChange = (value, mode) => {}
  const [refresh, setRefresh] = useState(false);

  

  const [currentLanguage, setCurrentLanguage] = useState("")
  const paginatedTrainingData = useSelector(
    state => state.Home.trainingSchedule
  )

  const { users } = useSelector(state => state.login.loginInfo)
  const [companyId, setCompanyId] = useState()

  useEffect(() => {
    if (users.user_type) {
      if (users.user_type === "company") {
        setCompanyId(users.company_detail[0].id)
      } else if (users.user_type === "business_admin") {
        setCompanyId(users.employee_company_detail[0].company)
      }
    }
  }, [users])

  useEffect(() => {
    getLanguage().then(lan => setCurrentLanguage(lan))
  }, [])

  useEffect(() => {
    if (companyId && currentLanguage?.length > 0) {
      dispatch(getTrainingSchedule(companyId, "", 1, currentLanguage))
    }
  }, [companyId, currentLanguage, refresh])

  useEffect(() => {
    getLanguage().then(lang => {
      moment.locale(lang, {
        week: {
          dow: 0
        }
      })
      strings.setLanguage(lang)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (calendarData?.safety_meetings && paginatedTrainingData?.length > 0) {
      getDates(paginatedTrainingData)
    } else {
      setDates([])
    }
  }, [calendarData, paginatedTrainingData])

  const getDates = videos => {
    const dates = getMarkDatesNew(videos)
    setDates(dates)
    console.log("DATES_EXTRACTED", dates)
  }

  const getMarkDatesNew = trainingSchedule => {
    const plan = trainingSchedule[0]?.company_plan_type
    let daysOffset

    if (plan == "BASIC") {
      daysOffset = 28
    } else {
      daysOffset = 14
    }
    const scheduleStatus = []
    let lastMeetingDate = null

    for (const item of trainingSchedule) {
      if (item.meeting_date) {
        lastMeetingDate = item.meeting_date
        scheduleStatus.push(item.meeting_date)
      } else {
        const diff = item.video_number - item.first_video_number
        const firstMeetingDate = item.first_meeting_date

        const baseDate = moment(firstMeetingDate, "YYYY-MM-DD")
        const daysFuture = daysOffset * diff
        const newDate = baseDate.add(daysFuture, "days")
        scheduleStatus.push(newDate.format("yyyy-MM-DD"))
      }
    }
    return scheduleStatus
  }

  const renderCustomHeader = ({ value, type, onChange }) => {
    const handlePrev = days => {
      const newValue = value.clone().subtract(days, type)
      onChange(newValue)
      setShowScheduleModal(false)
      setShowPasswordModal(false)
    }

    const handleNext = days => {
      const newValue = value.clone().add(days, type)
      onChange(newValue)
      setShowScheduleModal(false)
      setShowPasswordModal(false)
    }
    const isPreviousDate = () => {
      const currentDate = moment()
      const givenDate = moment(value.$d).format("YYYY-MM-DD")
      return currentDate.isBefore(givenDate, "day")
    }
    const getFifthYearDate = () => {
      const currentDate = moment()
      const fifthYearDate = moment(currentDate).add(4, "years")
      const newDate = moment(fifthYearDate)
        .month(11)
        .endOf("month")
        .format("YYYY-MM")

      const givenDate = moment(value.$d).format("YYYY-MM")
      const isTrue = newDate !== givenDate
      return isTrue
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 20
        }}
      >
        <div>
          <MonthDropdown
            handlePrev={handlePrev}
            value={value}
            handleNext={handleNext}
          />
        </div>
        <div style={{ display: "flex", gap: 50, cursor: "pointer" }}>
          <span onClick={() => isPreviousDate() && handlePrev(1)}>
            <img src={Images.backward} alt="" />
          </span>
          <span onClick={() => getFifthYearDate() && handleNext(1)}>
            <img src={Images.forward} alt="" />
          </span>
        </div>
      </div>
    )
  }

  function disabledDate(current) {
    const today = moment()
    const dayOfWeek = current.day()

    // Disable previous year dates, Saturdays (day 6), and Sundays (day 0)
    return (
      current.year() < today.year() ||
      (current.year() === today.year() && current.isBefore(today, "day")) ||
      dayOfWeek === 6 ||
      dayOfWeek === 0
    )
  }
  useEffect(() => {
    dispatch(getCalendar())
  }, [refresh])

  const callBack = () => {
    setShowScheduleModal(false)
    setRefresh(prev => !prev);
  }

  const dateCellRender = date => {
    return (
      <div className="table-cell-content">
        <p>{strings.CALENDER_SAFETY_MEETING}</p>
      </div>
    )
  }

  const cellRender = date => {
    if (dates.includes(moment(date.$d).format("YYYY-MM-DD"))) {
      return dateCellRender(date)
    }
    return null
  }

  const selectCell = date => {
    if (calendarData) {
      if (calendarData.safety_meetings) {
        setShowPasswordModal(true)
        setSelectedDate(date.$d)
      } else {
        setShowScheduleModal(date.$d)
        if (calendarData) {
          setDateId(calendarData.safety_meeting_id)
        } else {
          setDateId(false)
        }
      }
    }
  }

  const passwordCallBack = () => {
    setShowPasswordModal(false)
    setShowScheduleModal(selectedDate)
    if (calendarData) {
      setDateId(calendarData.safety_meeting_id)
    } else {
      setDateId(false)
    }
  }

  return (
    <CompanyLayout isActive={`${strings.CALENDAR}`}>
      <div className="upper-container">
        <h2>{strings.CALENDAR}</h2>
        <p>
          {strings.TAP_DATE_ON_CALENDAR_TO}{" "}
          <span>{strings.SCHEDULE_FIRST_SAFETY_MEETING}</span>
        </p>
      </div>

      <div className="calender-container">
        <ConfigProvider locale={lang === "es" ? esES : enEn}>
          {loading ? (
            <div className="calender-spinner-wrapper">
              <Spinner as="div" animation="border" size="xl" />
            </div>
          ) : (
            <Calendar
              locale={lang === "es" ? esES : enEn}
              onPanelChange={onPanelChange}
              onSelect={date => selectCell(date, true)}
              disabledDate={disabledDate}
              cellRender={cellRender}
              headerRender={renderCustomHeader}
            />
          )}
        </ConfigProvider>
        {/* <div className="calendar-footer">
          <div className="footer-title">{strings.IMPORTANT_NOTE_PLEASE_READ}</div>
          <div className="footer-text">
           {strings.ON_THE_FOLLOWING_SCREEN_PLEASE_ADD}
          </div>
        </div> */}
      </div>
      <ScheduleMeetingModal
        show={showScheduleModal}
        onHide={() => setShowScheduleModal(false)}
        // setEmployeeModal={() => setShowScheduleModal(false)}
        callBack={callBack}
        dateId={dateId}
        // loading={loading}
      />
      <PasswordModal
        show={showPasswordModal}
        onHide={() => {
          setShowPasswordModal(false)
        }}
        passwordCallBack={passwordCallBack}
      />
    </CompanyLayout>
  )
}

export default CompanyCalender
